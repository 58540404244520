<template>
<section>
     <loading v-if="isLoading"></loading>
 <div v-html="HelpInfo.info"></div>
</section>
</template>
<script>
import apis from "@/utils/crypt"
import loading from "../components/M5Loading"
export default {
    name:"M5AddFav",
    components:{loading},
     data (){
        return {
            HelpInfo:{
                isSuccess:false,
                info:""

            },
            isLoading:true

        }
    },
    mounted(){
            this.$ajax.post(this.Url.HelpAddr,{
             m:apis.rsaEncode("addfav")
            }).then(
             response => {
      
            this.HelpInfo.info = response.data.Help.htmlcode
            this.HelpInfo.isSuccess = true
            this.isLoading = false

            
        
            })}
}
</script>
<style>
    
</style>